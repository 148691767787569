<template>
  <el-switch v-model="filter.value">
  </el-switch>
</template>

<script>
import filtersGeneratorMixin from '../mixin/filtersGeneratorMixin';

export default {
  name: 'fieldSwitch',
  mixins: [filtersGeneratorMixin],

  created() {
    if (this.filter.name === 'is_active' && !this.filter.value && (typeof this.filter.value === 'undefined' || this.filter.value === null)) {
      this.filter.value = true;
    }
  },
};
</script>

<style scoped>

</style>
